var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-about"},[_c('div',{staticClass:"container"},[_c('Crumbs',{attrs:{"name":"工作机会"}}),(_vm.showdz)?_c('div',{staticClass:"title"},[_vm._v("电力电子硬件工程师 （AE方向）")]):_c('div',{staticClass:"technicalEngineer flex-sb"},[_c('div',{staticClass:"title"},[_vm._v("电力电子硬件工程师 （AE方向）")]),_c('div',{staticClass:"viewDetail",on:{"click":function($event){return _vm.whoShow('showdz')}}},[_vm._v("查看详情")])]),(_vm.showdz)?_c('div',{staticClass:"careerDetails"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.showxs)?_c('div',{staticClass:"title"},[_vm._v("技术销售工程师")]):_c('div',{staticClass:"technicalEngineer flex-sb"},[_c('div',{staticClass:"title"},[_vm._v("技术销售工程师")]),_c('div',{staticClass:"viewDetail",on:{"click":function($event){return _vm.whoShow('showxs')}}},[_vm._v("查看详情")])]),(_vm.showxs)?_c('div',{staticClass:"careerDetails"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),_vm._m(8)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position"},[_c('img',{staticClass:"positionIcon",attrs:{"src":require("../../assets/img/contactUs/address.png"),"alt":""}}),_vm._v(" Base：上海 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobResponsibilities"},[_c('h2',{staticClass:"subTitle"},[_vm._v("岗位职责")]),_c('ul',[_c('li',[_vm._v("为客户提供现场技术支持，解决常规应用问题；")]),_c('li',[_vm._v(" 与器件/系统工程师合作，为终端客户提供技术解决方案和技术建议； ")]),_c('li',[_vm._v("搜集市场上的应用需求和新的设计机会;")]),_c('li',[_vm._v("配合技术销售工程师拓展产品市场和取得销售订单；")]),_c('li',[_vm._v("配合系统、IC、器件、封装工程师完成产品定义；")]),_c('li',[_vm._v("展会演示，技术会议做产品报告。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobRequirements"},[_c('h2',{staticClass:"subTitle"},[_vm._v("任职要求")]),_c('ul',[_c('li',[_vm._v("本科及以上学历，电力电子、电气、电子及相关专业;")]),_c('li',[_vm._v("熟悉电路与功率转换拓扑;")]),_c('li',[_vm._v("熟悉功率器件与驱动电路;")]),_c('li',[_vm._v("具备出色的实验动手能力与问题解决能力；")]),_c('li',[_vm._v("能快速响应客户需求，适应出差工作任务；")]),_c('li',[_vm._v("有良好的自我驱动和工作安排能力；")]),_c('li',[_vm._v(" 有一定业务开拓能力、公关能力和客户服务意识；了解和掌握竞争对手的产品市场策略；具有较强的抗压能力。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"priorityConditions"},[_c('h2',{staticClass:"subTitle"},[_vm._v("优先条件")]),_c('ul',[_c('li',[_vm._v("具备宽禁带半导体器件知识及其应用经验；")]),_c('li',[_vm._v("具备AC/DC,DC/DC转换开发经验；")]),_c('li',[_vm._v(" 具备出色的人际交往能力、沟通表达能力，并建立良好的客户关系； ")]),_c('li',[_vm._v("具备FAE工作经验者优先；")]),_c('li',[_vm._v("熟悉车载OBC/DC-DC，光伏逆变器应用的优先。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position"},[_c('img',{staticClass:"positionIcon",attrs:{"src":require("../../assets/img/contactUs/address.png"),"alt":""}}),_vm._v(" Base：上海 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobResponsibilities"},[_c('h2',{staticClass:"subTitle"},[_vm._v("任职资格")]),_c('ul',[_c('li',[_vm._v("全日制本科及以上学历；")]),_c('li',[_vm._v(" 市场营销、电子相关专业优先。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobRequirements"},[_c('h2',{staticClass:"subTitle"},[_vm._v("工作职责")]),_c('ul',[_c('li',[_vm._v("负责新客户的开发以及现有客户关系的维护，销售业绩的达成；")]),_c('li',[_vm._v("负责供应链体系客户的关系维护和业绩目标的达成；")]),_c('li',[_vm._v("负责终端客户的销售渠道管理；")]),_c('li',[_vm._v("负责整体销售业绩的达成；")]),_c('li',[_vm._v("上级领导安排的其他业务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"priorityConditions"},[_c('h2',{staticClass:"subTitle"},[_vm._v("岗位要求")]),_c('ul',[_c('li',[_vm._v("熟悉半导体市场，1-3年半导体营销经验，熟悉电子元器件分销领域，具有电子元器件（销售IC,二三极管，MOS管等）销售行业经验；熟悉碳化硅市场优先")]),_c('li',[_vm._v("具有极强的沟通能力和执行能力，具备良好的团队领导力和开拓进取精神；")]),_c('li',[_vm._v(" 以目标、业绩为导向； ")]),_c('li',[_vm._v("勇于进取，接受挑战，责任心强；")]),_c('li',[_vm._v("勤奋向上，为人诚实可靠、品行端正。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resume"},[_c('a',{staticClass:"resumeBtn",attrs:{"href":"mailto:songliying@sicred.cn"}},[_vm._v(" 投递简历 "),_c('img',{staticClass:"rightArrow",attrs:{"src":require("../../assets/img/contactUs/resumeRight.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"user",attrs:{"src":require("../../assets/img/contactUs/user.png"),"alt":""}}),_vm._v(" 联系人：宋小姐 ")]),_c('div',[_c('img',{staticClass:"email",attrs:{"src":require("../../assets/img/contactUs/email.png"),"alt":""}}),_vm._v(" 邮箱：songliying@sicred.cn ")])])
}]

export { render, staticRenderFns }