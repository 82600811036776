<template>
  <section class="section-about">
    <div class="container">
      <Crumbs name="工作机会" />
      <div class="title" v-if="showdz">电力电子硬件工程师 （AE方向）</div>
      <div class="technicalEngineer flex-sb" v-else>
        <div class="title">电力电子硬件工程师 （AE方向）</div>
        <div class="viewDetail" @click="whoShow('showdz')">查看详情</div>
      </div>
      <div class="careerDetails"  v-if="showdz">
        <!-- 定位 -->
        <div class="position">
          <img class="positionIcon" src="../../assets/img/contactUs/address.png" alt="" />
          Base：上海
        </div>
        <!-- 岗位职责 -->
        <div class="jobResponsibilities">
          <h2 class="subTitle">岗位职责</h2>
          <ul>
            <li>为客户提供现场技术支持，解决常规应用问题；</li>
            <li>
              与器件/系统工程师合作，为终端客户提供技术解决方案和技术建议；
            </li>
            <li>搜集市场上的应用需求和新的设计机会;</li>
            <li>配合技术销售工程师拓展产品市场和取得销售订单；</li>
            <li>配合系统、IC、器件、封装工程师完成产品定义；</li>
            <li>展会演示，技术会议做产品报告。</li>
          </ul>
        </div>

        <!-- 任职要求 -->
        <div class="jobRequirements">
          <h2 class="subTitle">任职要求</h2>
          <ul>
            <li>本科及以上学历，电力电子、电气、电子及相关专业;</li>
            <li>熟悉电路与功率转换拓扑;</li>
            <li>熟悉功率器件与驱动电路;</li>
            <li>具备出色的实验动手能力与问题解决能力；</li>
            <li>能快速响应客户需求，适应出差工作任务；</li>
            <li>有良好的自我驱动和工作安排能力；</li>
            <li>
              有一定业务开拓能力、公关能力和客户服务意识；了解和掌握竞争对手的产品市场策略；具有较强的抗压能力。
            </li>
          </ul>
        </div>

        <!-- 优先条件 -->
        <div class="priorityConditions">
          <h2 class="subTitle">优先条件</h2>
          <ul>
            <li>具备宽禁带半导体器件知识及其应用经验；</li>
            <li>具备AC/DC,DC/DC转换开发经验；</li>
            <li>
              具备出色的人际交往能力、沟通表达能力，并建立良好的客户关系；
            </li>
            <li>具备FAE工作经验者优先；</li>
            <li>熟悉车载OBC/DC-DC，光伏逆变器应用的优先。</li>
          </ul>
        </div>
      </div>
      <div class="title"  v-if="showxs">技术销售工程师</div>
      <div class="technicalEngineer flex-sb" v-else>
        <div class="title">技术销售工程师</div>
        <div class="viewDetail"  @click="whoShow('showxs')">查看详情</div>
      </div>
      <div class="careerDetails" v-if="showxs">
        <!-- 定位 -->
        <div class="position">
          <img class="positionIcon" src="../../assets/img/contactUs/address.png" alt="" />
          Base：上海
        </div>
        <!-- 岗位职责 -->
        <div class="jobResponsibilities">
          <h2 class="subTitle">任职资格</h2>
          <ul>
            <li>全日制本科及以上学历；</li>
            <li>
              市场营销、电子相关专业优先。
            </li>
          </ul>
        </div>

        <!-- 任职要求 -->
        <div class="jobRequirements">
          <h2 class="subTitle">工作职责</h2>
          <ul>
            <li>负责新客户的开发以及现有客户关系的维护，销售业绩的达成；</li>
            <li>负责供应链体系客户的关系维护和业绩目标的达成；</li>
            <li>负责终端客户的销售渠道管理；</li>
            <li>负责整体销售业绩的达成；</li>
            <li>上级领导安排的其他业务。</li>
          </ul>
        </div>

        <!-- 优先条件 -->
        <div class="priorityConditions">
          <h2 class="subTitle">岗位要求</h2>
          <ul>
            <li>熟悉半导体市场，1-3年半导体营销经验，熟悉电子元器件分销领域，具有电子元器件（销售IC,二三极管，MOS管等）销售行业经验；熟悉碳化硅市场优先</li>
            <li>具有极强的沟通能力和执行能力，具备良好的团队领导力和开拓进取精神；</li>
            <li>
              以目标、业绩为导向；
            </li>
            <li>勇于进取，接受挑战，责任心强；</li>
            <li>勤奋向上，为人诚实可靠、品行端正。</li>
          </ul>
        </div>
      </div>
      <div class="resume">
        <a href="mailto:songliying@sicred.cn" class="resumeBtn">
          投递简历
          <img
            class="rightArrow"
            src="../../assets/img/contactUs/resumeRight.png"
            alt=""
          />
        </a>
        <div>
          <img class="user" src="../../assets/img/contactUs/user.png" alt="" />
          联系人：宋小姐
        </div>
        <div>
          <img class="email" src="../../assets/img/contactUs/email.png" alt="" />
          邮箱：songliying@sicred.cn
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Crumbs from "../../components/Crumbs";
export default {
  components: {
    Crumbs,
  },
  data() {
    return {
      showdz: true,
      showxs: false,
    };
  },
  methods:{
    whoShow(e){
      if(e == 'showdz'){
        this.showdz = true;
        this.showxs = false;
      }
      if(e == 'showxs'){
        this.showdz = false;
        this.showxs = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.section-about {
  background-color: #fff;
}
.careerDetails {
  width: 100%;
  background-color: #f5f5f5;
  padding: 30px 40px;
  margin-bottom: 45px;
  .position {
    display: flex;
    .positionIcon {
      width: 14px;
      height: 18px;
      margin-right: 8px;
      margin-top: 4px;
      vertical-align: middle;
    }
  }
  .jobResponsibilities {
    margin-bottom: 40px;
  }
  .subTitle {
    font-size: 18px;
    margin: 30px 0;
    color: #333;
    font-weight: 700;
  }
  ul {
    margin-left: 40px;
    li {
      list-style-type: disc;
    }
  }
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.technicalEngineer {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 80px;
  .viewDetail {
    font-size: 20px;
    padding: 5px 20px;
    background-color: #f5f5f5;
    border-radius: 6px;
    cursor: pointer;
    color: #333;
  }
}
.resume {
  margin-bottom: 80px;
  .resumeBtn {
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 5px 27px;
    margin-bottom: 10px;
    background-color: #4fbfe9;
    font-size: 20px;
    border-radius: 2px;
    cursor: pointer;
    .rightArrow {
      width: 17px;
      height: 10px;
      vertical-align: middle;
    }
  }
  .user {
    width: 24px;
    height: 26px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .email {
    width: 24px;
    height: 26px;
    margin-right: 10px;
  }
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
    .section-about{
    padding: 15px 0;
    background: #fff;
    }
    .title{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .technicalEngineer {
      margin-bottom: 30px;
        .viewDetail {
            font-size: 16px;
            padding: 5px 10px;
            background-color: #f5f5f5;
            border-radius: 6px;
            cursor: pointer;
            color: #333;
        }
    }
    .careerDetails{
      padding: 15px;
      .subTitle{
        margin: 15px 0;
        font-size: 16px;
      }
      .jobResponsibilities{
        margin-bottom: 20px;
      }
    }
    .resume{
      margin-bottom: 20px;
      .resumeBtn {
        font-size: 14px;
      }
    }

 }

</style>