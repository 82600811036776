<template>
  <div>
    <Header :tabActive="5"/>
    <ScrollSmall :pageSmallTile="pageSmallTile" />
    <ContactUsContent />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header';
import ScrollSmall from '../components/ScrollSmall';
import ContactUsContent from '../components/contactUs/ContactUs-content';
import Footer from '../components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    ScrollSmall,
    ContactUsContent,
    Footer
  }
  ,
  data () {
    return {
      pageSmallTile: {
        title: '工作机会',
        url: 'http://img.sicred.cn/assets/img/banner/contactUs.png',
        tis: [
          {
            name:'JOB OPPORTUNITY',
            url:'#/contact-us'
          }
        ]
      },
      info: {
        title: '',
        name: '',
        email: '',
        city: '',
        remark: '',
      }
    }
  },
  methods: {
    dr_ajax_submit () {
      console.log(this.info);
      this.$message({
        type: 'warning',
        message: `敬请期待！`
      });
    }
  }
}
</script>

<style   lang="less">
.fc-form-body {
  background: #efefef;
  padding: 30px;
  border-radius: 10px;
  .form-group {
    padding: 20px;
  }
}
</style>
